<template>
  <article>

    <InformationListAccordion
      v-if="category.whole_information_list && category.whole_information_list.length"
      :informations="category.whole_information_list"
      :content-class="'whole-information'"
      title="重要なお知らせ"
      noticeText="重要"
    />

    <category-content-section
      :data="category"
      :artist_page_url="artist_page_url"
      :event_page_url="event_page_url"
      :show-sale-term="false"
      :current-artist="currentArtist"
    />

    <div v-if="category.news && category.news.length" class="content__inner">
      <InformationListAccordion
        :informations="category.news"
        :content-class="'category-main__news'"
        title="お知らせ"
        noticeText="INFO"
      />
    </div>

    <section class="content pt-50 bg-white">

      <div class="content__inner pb-50">

        <h3 class="goods-title">
          <div ref="goodsCategoryTitle" class="goods-title__text">
            <keep-proportion-content
              :proportion-target="() => $refs.goodsCategoryTitle"
              :style="{ color: category.category_name_text_color }"
              :content="category.category_name"
              :p-width="category.is_limited ? 862 : 980"
              :gap="0"
              :gap-sp="0"
              blank-to-br
            />
          </div>
          <span v-if="category.is_limited" class="goods-title__label">{{ $t('category_goods.limited_merchandise') }}</span>
        </h3>

        <dl class="category-dl mb-30">
          <dt :style="{ color: category.category_name_text_color }" class="category-dl__name">
            {{ category.sale_product_name }}
          </dt>
          <dd class="category-dl__price">
            <div class="price-title">{{ $t('category_goods.selling_price') }}</div>
            <div class="price-detail">
              <p>
                ¥{{ category.product_standard_total_amount_with_tax }}
                <span class="small">({{$t('category_goods.tax_included') }})/1{{ $t('category_goods.piece') }}</span>
              </p>
            </div>
          </dd>
        </dl>

        <category-sale-term
          :data="category"
          class="mb-50"
        />

        <div class="mb-40">
          <Headline :title="$t('category_goods.about_the_product')"/>
          <div ref="description" class="category__description">

            <keep-proportion-content
              :proportion-target="() => $refs.description"
              :content="category.sale_product_description"
              :gap="0"
              :gap-sp="0"
            />

          </div>
          <vue-slick-carousel
            v-if="Array.isArray(category.sale_product_detail_images) && category.sale_product_detail_images.length"
            ref="saleProductCarousel"
            class="category__carousel mt-30"
            v-bind="carouselSetting"
          >
            <div
              v-for="(sale_product_detail_image, index) in category.sale_product_detail_images"
              :key="`sale_product_carousel_${index}`"
              class="category__carousel__image-wrapper"
              @click="onCarouselImageClick(sale_product_detail_image,'sale_product_detail_images', index)"
            >
              <img
                class="category__carousel__image"
                :src="sale_product_detail_image.image_url"
                draggable="false"
                alt=""
              />
            </div>
          </vue-slick-carousel>
        </div>

        <!--    #### スペック      -->
        <div v-if="category.product_standard_display_attribute" ref="goodsCategorySpec" class="category-spec">
          <Headline :title="$t('category_goods.spec')"/>
          <keep-proportion-content
            :proportion-target="() => $refs.goodsCategorySpec"
            :content="category.product_standard_display_attribute"
            :p-sp-width="380"
            :gap="0"
            :gap-sp="0"
          />

          <vue-slick-carousel
            v-if="Array.isArray(category.product_standard_sample_images) && category.product_standard_sample_images.length"
            ref="productStandardCarousel"
            class="category__carousel mt-30"
            v-bind="carouselSetting"
          >
              <div
                v-for="(product_standard_sample_image, index) in category.product_standard_sample_images"
                :key="`product_standard_carousel_${index}`"
                class="category__carousel__image-wrapper"
                @click="onCarouselImageClick(product_standard_sample_image,'product_standard_sample_images', index)"
              >
                <img
                  class="category__carousel__image"
                  :src="product_standard_sample_image.image_url"
                  draggable="false"
                  alt=""
                />
              </div>
          </vue-slick-carousel>

        </div>


      </div>

      <div class="bg-gray category">

        <div class="content__inner">

          <validation-observer
            ref="regist_observer"
            v-slot="{ handleSubmit }" class="content__inner"
            tag="div"
          >

            <validation-provider
              v-slot="{ errors }"
              :name="$t('category_goods.quantity')"
              :rules="`required|${category.sale_product_always_keep_in_stock ? '' : `max_value:${category.sale_product_number_of_stock}`}|min_value:1`"
            >

              <dl class="category-goods">

                <dd class="category-goods__stock">

                  <p>{{ $t('category_goods.quantity') }}：</p>

                  <div>
                    <input
                      v-model="_purchaseItem.qty"
                      class="form-text" min="0"
                      name="number"
                      type="number"
                    />
                    <br>
                    <span v-if="errors[0]" class="c-red"> {{ errors[0] }}</span>
                  </div>

                  <p v-if="!category.sale_product_always_keep_in_stock" class="small">
                    （{{ $t('category_goods.stock_quantity')}}：{{ category.sale_product_number_of_stock }} {{$t('category_goods.set') }}）
                  </p>

                </dd>

                <dd class="category-goods__cart">
                  <a class="btn-100" @click="handleSubmit(onAddToCart)">
                    {{ $t('category_goods.add_product_to_cart') }}
                  </a>
                </dd>

              </dl>


            </validation-provider>


          </validation-observer>

          <dl class="category-dl">

            <dt class="category-dl__cart">{{ $t('category_goods.in_the_current_cart') }}</dt>

            <dd class="category-dl__count first">
              <p>
                <span class="small">{{ $t('category_goods.quantity') }}：</span>{{ product_current_qty_in_cart }}
              </p>
            </dd>

            <dd class="category-dl__count">
              <p>
                ¥{{ product_current_total_include_tax }}
                <span class="small">({{ $t('category_goods.tax_included') }})</span>
              </p>
            </dd>

            <dd class="category-dl__link">
              <a class="pointer" @click="$router.push({ name: 'cart.page' })">
                {{ $t('category_goods.see_the_cart')}}
              </a>
            </dd>

          </dl>

        </div>

      </div>

    </section>

    <ChildUnderEvent
      v-if="category.event_id && hasSaleProduct"
      :event_id="category.event_id"
      :except_category_id="except_category_id"
      :filters="filters"
      :product-standard="productStandard"
      :sale-products="saleProducts"
      :categories="categories"
      :subtitle="$t('category_goods.relation.subtitle')"
      :title="$t('category_goods.relation.title')"
      @filter-change="handleFilterChange"
    />

    <CarouselSelectModal
      ref="carouselPopup"
      :popup-image="this.carouselPopupImage"
      @close="handleOnClose"
      @next="handleOnNext"
      @prev="handleOnPrevious"
    />

  </article>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import KeepProportionContent from '../base/KeepProportionContent'

export default {
  name: "CategoryGoodsTemplate",
  components: {
    VueSlickCarousel,
    KeepProportionContent,
    InformationListAccordion: () => import('@components/base/InformationListAccordion'),
    categoryContentSection: () => import('@components/category/CategoryContentSection'),
    CategorySaleTerm: () => import('@components/category/CategorySaleTerm'),
    ChildUnderEvent: () => import('@pages/event/ChildUnderEvent'),
    Headline: () => import("@components/base/Headline"),
    CarouselSelectModal: () => import('@components/category/CarouselSelectModal'),
  },
  model: {
    prop: 'purchaseItem',
    event: 'update'
  },
  data() {
    return {
      carouselPopupTarget: '',
      carouselPopupImage: {}
    }
  },
  props: {
    category: {
      type: Object,
      required: true
    },
    artist_page_url: {
      type: String,
      default: ''
    },
    event_page_url: {
      type: String,
      default: ''
    },

    purchaseItem: {
      type: Object,
    },

    product_current_qty_in_cart: {
      type: [ Number, String ],
      default: 0
    },

    product_current_total_include_tax: {
      type: [ Number, String ],
      default: 0
    },

    except_category_id: {
      type: String,
    },
    filters: {
      type: Object
    },
    productStandard: {
      type: Object
    },
    categories: {
      type: Array
    },
    saleProducts: {
      type: Array
    },
    carouselSetting: {
      type: Object,
      default:() => {}
    },
    currentArtist: {
      type: Object,
      required: true
    }
  },
  created() {
    window.scrollTo(0, 0)
  },
  computed: {
    _purchaseItem: {
      get() {
        return this.purchaseItem
      },
      set(value) {
        this.$emit('update-purchase-item', value)
      }
    },
    hasSaleProduct() {
      if (!this.saleProducts) return false
      return Object.keys(this.saleProducts).length
    },
    hasProductStandard() {
      if (!this.productStandard) return false
      const { normal, printed } = this.productStandard
      return (Array.isArray(normal) && normal.length) || (Array.isArray(printed) && printed.length)
    },
  },
  methods: {
    onAddToCart() {
      this.$emit('add-to-cart', {
        purchasable_type: this.category.purchasable_type,
        sale_product_id:  this.category.sale_product_id,
        qty: this._purchaseItem.qty,
      })
    },
    handleFilterChange({key, value}) {
      this.$emit('filter-change', { key, value})
    },
    onCarouselImageClick(product, target, index) {
      this.carouselPopupTarget = target
      this.setCarouselPopupImage(product, index)
    },
    setCarouselPopupImage(product, index) {
      if (this.category && this.category[this.carouselPopupTarget]) {
        const popupTargetImagesLength = this.category[this.carouselPopupTarget].length
        this.carouselPopupImage = {
          id: product.id,
          image_url: product.image_url,
          index,
          first: index === 0,
          last: index === (popupTargetImagesLength - 1)
        }
        this.$refs.carouselPopup.show()
      } else {
        this.handleOnClose()
      }
    },
    handleOnClose() {
      this.carouselPopupTarget = ''
      this.carouselPopupImage = {}
      this.$refs.carouselPopup.hide()
    },
    handleOnNext(popupImage) {
      if (!popupImage.last) {
        const nextPopupImage = this.category[this.carouselPopupTarget][popupImage.index + 1]
        this.setCarouselPopupImage(nextPopupImage, popupImage.index + 1)
      } else {
        this.handleOnClose()
      }
    },
    handleOnPrevious(popupImage) {
      if (!popupImage.first) {
        const prevPopupImage = this.category[this.carouselPopupTarget][popupImage.index - 1]
        this.setCarouselPopupImage(prevPopupImage, popupImage.index - 1)
      } else {
        this.handleOnClose()
      }
    },
  }
}
</script>
